<template>
  <Modal v-model="changeCurrencyModal" :title="changeCurrencyTitle" width="500" class-name="changeCurrencyStyle"
    :transfer="false" :mask-closable="false" @on-visible-change="changeCurrencyChange">
    <Form ref="currencyForm" :model="currencyForm" :rules="ruleValidate" :label-width="100" @submit.native.prevent>
      <Row type="flex" :gutter="gutterItem" style="width: 90%;">
        <Col :xxl="oneItemCol" :xl="oneItemCol" :lg="oneItemCol" :md="oneItemCol">
          <Form-item :label="currencylabel + '：'" prop="currency">
            <Select v-model="currencyForm.currency" transfer filterable>
              <Option v-for="(item, index) in currencyList" :key="index" :value="item.value">{{ item.label }}</Option>
            </Select>
          </Form-item>
        </Col>
      </Row>
    </Form>
    <template #footer>
      <Button @click="changeCurrencyModal = false">{{ $t('key1000097') }}</Button>
      <Button type="primary" @click="saveCurrencyBtn">{{ $t('key1000374') }}</Button>
    </template>
  </Modal>
</template>

<script>
import { alias689d3582fa8c4a7891f9d6ea8bcd03a9 } from '@/customFolder/customVueAlias.js';

import Mixin from "@/components/mixin/common_mixin";
import api from '@/api/';

export default {
  name: "changeCurrencyModal",
  mixins: [Mixin],
  data() {
    let v = this;
    return {
      changeCurrencyModal: false,
      changeCurrencyTitle: '',
      currencyForm: {
        currency: null
      },
      ruleValidate: {
        currency: [
          {required: true, validator: v.validateCurrency(), trigger: 'change'}
        ],
      },
      currencylabel: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000529'),
      currencyType: null,
      currencyList: []
    }
  },
  computed: {
    // 获取当前登录用户的信息
    userInfo() {
      if (this.$store.state.erpConfig) {
        return this.$store.state.erpConfig.userInfo;
      } else {
        return '';
      }
    },
  },
  methods: {
    // 初始化币种数据
    initCurrencyData(type, merchantType, currency) {
      let v = this;
      let titleObj = {
        addSupplier: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000530'),
        addDistributor: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000531'),
        editSupplier: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000532'),
        editDistributor: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000532')
      };
      v.changeCurrencyTitle = titleObj[type];
      let currencyObj = {
        addSupplier: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000529'),
        addDistributor: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000533'),
        editSupplier: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000534'),
        editDistributor: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000534')
      }
      v.currencylabel = currencyObj[type];
      v.currencyType = type;
      v.getCurrencyBasicsData(type, merchantType, currency);
    },
    // 获取币种的基础数据
    getCurrencyBasicsData(type, merchantType, currency) {
      let v = this;
      if (['addSupplier', 'addDistributor'].includes(type)) {
        v.getCurrencyInfo().then(() => {
          v.changeCurrencyModal = true;
        });
      } else {
        v.systemCurrencyData(merchantType, currency);
      }
    },
    // 获取对应系统设置的币种
    systemCurrencyData(merchantType, currency) {
      let v = this;
      v.currencyList = [];
      v.axios.get(api.get_ymsCurrencyConfig_query + `?merchantType=${merchantType}`).then(response => {
        if (response.data.code === 0) {
          let data = response.data.datas;
          if (data && data.length > 0) {
            data.forEach((n) => {
              let label = n.targetCurrencyCode;
              if (n.currencyName) {
                label = label + '-' + n.currencyName;
              }
              n.label = label;
              n.value = n.targetCurrencyCode;
            });
            v.currencyList = data;
            v.currencyForm.currency = currency;
          }
          v.changeCurrencyModal = true;
        }
      });
    },
    // 保存币种
    saveCurrencyBtn() {
      let v = this;
      // 在运营系统---新增分销商、供应商币种
      if (['addSupplier', 'addDistributor'].includes(v.currencyType)) {
        let query = {
          currency: v.currencyForm.currency,
          merchantType: v.currencyType === 'addSupplier' ? 2 : v.currencyType === 'addDistributor' ? 3 : 1  // 1运营 2供应商 3分销商
        }
        v.$refs['currencyForm'].validate((valid) => {
          if (valid) {
            v.axios.post(api.post_ymsCurrencyConfig_insert, query, {loading: true, loadingText: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000212')}).then(response => {
              if (response.data.code === 0) {
                v.changeCurrencyModal = false;
                v.$Message.success(alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000170'));
                v.$emit('updateData', query.merchantType);
              }
            });
          }
        });
      }
      // 在分销商、供应商系统修改币种
      else {
        let url = '';
        if (v.currencyType === 'editDistributor') {
          url = api.post_ymsDistributorMerchant_saveCurrency;
        } else {
          url = api.post_ymsSupplierMerchant_saveCurrency;
        }
        v.axios.post(url + `?currency=${v.currencyForm.currency}`, null, {loading: true, loadingText: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000212')}).then(res => {
          if (res.data.code === 0) {
            v.$Message.success(alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000130'));
            v.changeCurrencyModal = false;
            v.$emit('updateData', true);
          }
        });
      }
    },
    // 校验币种不能为空
    validateCurrency() {
      let v = this;
      return function (rule, value, callback) {
        if (!value) {
          return callback(new Error(`${v.currencylabel}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000528')}`));
        } else {
          callback();
        }
      };
    },
    // 监听添加币种弹窗
    changeCurrencyChange(value) {
      if (!value) {
        this.$refs['currencyForm'].resetFields();
      }
    }
  }
}
</script>

<style scoped>

</style>